@import './assets/fonts/Raleway/raleway.scss';
@import './styles/variables.scss';

@import './styles/Appointments.scss';

html,
body {
    font-family: 'Raleway', sans-serif;
    font-weight: 600!important;
}

.m-h-100v {
    min-height: 100vh!important;
}

label {
    margin-top: 10px;
}

#wrapper {
    background-color: #fff;
    height: 100vh;
}

#basePage {
    padding-top: 30px;
}

#page-wrapper {
    min-height: 100vh!important;
    transition: .2s;
}

.fivef-pageTitle {
    color: $primaryTextColor;
    font-weight: 800;
    margin: 20px 0;
}

.MuiPaper-root {
    border: 1px solid #ECECEC;
    box-shadow: none!important;
}

.MuiTableCell-head {
    color: $primaryTextColor!important;
    font-size: 15px!important;
    font-weight: 800!important;
}

// .ant-select-selector {
//     border-radius: 10px!important;
//     border: none!important;
//     color: #3765F6!important;
//     -webkit-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
//     -moz-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
//     box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
// }

.ant-breadcrumb {
    a {
        color: $secondaryLightTextColor;
    }
}

.ant-modal-title {
    color: #4D4D4D !important;
    font-weight: 800 !important;
}

.ant-btn {
    border-radius: 7px !important;
}

.ant-btn-primary {
    background-color: $secondaryLightTextColor !important;
    border: none !important;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
    -moz-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
    box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2);
}

.ant-list {
    background-color: #fff!important;
    padding: 10px!important;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2)!important;
    -moz-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2)!important;
    box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2)!important;
}

// .ant-input,
.fivef-dropdown {
    border-radius: 10px!important;
    border: none!important;
    -webkit-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2)!important;
    -moz-box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2)!important;
    box-shadow: 0px 4px 5px 0px rgba(55,101,246,0.2)!important;
}

.Card-Grids {
    .ant-card-body {
        padding: 0 15px;

        .row {
            .ant-card-grid {
                width: -webkit-fill-available!important;
            }
        }
    }
}

/*.App {*/
/*  text-align: center;*/
/*}*/

/*.App-logo {*/
/*  height: 40vmin;*/
/*  pointer-events: none;*/
/*}*/

/*@media (prefers-reduced-motion: no-preference) {*/
/*  .App-logo {*/
/*    animation: App-logo-spin infinite 20s linear;*/
/*  }*/
/*}*/

/*.App-header {*/
/*  background-color: #282c34;*/
/*  min-height: 100vh;*/
/*  display: flex;*/
/*  flex-direction: column;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  font-size: calc(10px + 2vmin);*/
/*  color: white;*/
/*}*/

/*.App-link {*/
/*  color: #61dafb;*/
/*}*/

/*@keyframes App-logo-spin {*/
/*  from {*/
/*    transform: rotate(0deg);*/
/*  }*/
/*  to {*/
/*    transform: rotate(360deg);*/
/*  }*/
/*}*/

.modal-content {
    width: 800px;
    height: 600px;
}

.text-white {
    color: #fff;
}

.text-primary {
    color: #0170FE;
}

[class*="hover:text-primary"]:hover {
    color: #0170FE;
}

[class*="hover:text-danger"]:hover {
    color: #ff4d4f;
}

[class*="hover:cursor-pointer"]:hover {
    cursor: pointer;
}

.bg-green{
    background-color: #27ae60 !important;
}

[class*="hover:border-none"]:hover {
   border: none
}

.ant-card-head-title {
    white-space: normal !important;
}

.ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none
}

label.ant-radio-button-wrapper {
    font-weight: 400;
}

.cursor-pointer {
    cursor: pointer
}

.actionables {
    color: $secondaryLightTextColor;
    width: 28px !important;
}

// Antd Error Popup Style
.ant-message-custom-content.ant-message-error {
    display: flex;
    align-items: center;
}

.ant-select-dropdown-placement-bottomLeft {
    z-index: 99999999!important;
}

.messageTemplateTab {
    .ant-tabs-nav-wrap {
        display: none!important;
    }
}

.text-ellipsis {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.ant-upload-list-picture .ant-upload-list-item-error, .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-name {
    border-color: #d9d9d9!important;
    color: #d9d9d9!important;
}

span.ant-upload-list-item-name {
    color: #686868!important;
}

.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'], .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#e6f7ff'] {
    fill: #f1f1f1!important;
}

.ant-upload-list-picture .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'], .ant-upload-list-picture-card .ant-upload-list-item-error .ant-upload-list-item-thumbnail .anticon svg path[fill='#1890ff'],
.ant-upload-list-picture .ant-upload-list-item-thumbnail .anticon, .ant-upload-list-picture-card .ant-upload-list-item-thumbnail .anticon {
    color: #686868!important;
    fill: #686868!important;
}

